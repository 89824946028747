.variant-step {
  width: 80%;
  padding: 0;

  .header {
    padding: 20px 50px;
    height: 120px;
    background-color: #D7E1F3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;

    h3 {
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 18px;
      color: #000000;
    }

    .forms {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      h4 {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }

      .form {
        width: 330px;
        display: flex;
        flex-direction: column;
        gap: 18px;
        background-color: #F6F6F6;
        padding: 24px;
        border-radius: 24px;

        .days {
          display: flex;
          gap: 12px;
          margin-top: -10px;
          padding-left: 10px;

          span {
            font-size: 10px;
            color: #3A4457;
            font-style: italic;
          }
        }

        label {
          color: #525967;
          font-size: 12px;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 24px;

      .button--primary {
        padding: 10px 20px;
      }
    }
  }
}