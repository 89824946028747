.flux-lot {
  width: 80%;
  padding: 24px;
  overflow: auto;

  .info {
    display: flex;
    gap: 50px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h3 {
        font-size: 24px;
        font-weight: 800;
        color: #000000;
      }

      .sar-info {
        display: flex;
        flex-direction: column;
        gap: 18px;
        background-color: #F6F6F6;
        padding: 24px;
        border-radius: 24px;

        > div {
          display: flex;
          gap: 5px;
          flex-direction: column;
          span {
            font-size: 12px;
            font-weight: 400;
            color: #525967;
          }
          
          p {
            font-size: 16px;
            font-weight: 400;
            color: #212731;
          }

          ul {
            margin-left: 20px;
            li {
              font-size: 16px;
              font-weight: 400;
              color: #212731;
            }
          }
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 18px;
        min-width: 480px;
        background-color: #F6F6F6;
        padding: 24px;
        border-radius: 24px;
    
        label {
          font-size: 12px;
          font-weight: 400;
          color: #525967;
        }

        .days {
          display: flex;
          gap: 12px;
          margin-top: 10px;
          padding-left: 10px;

          span {
            font-size: 10px;
            color: #3A4457;
            font-style: italic;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;

    .button--primary {
      padding: 10px 20px;
    }
  }
}