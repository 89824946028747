.add-lot-mission {
  padding: 10px;
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    h2 {
      font-size: 18px;
      font-weight: 800;
      color: #161616;
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #212731;
    }
  }

  .form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 70%;
    }

    .days-input {
      width: 20%;
      margin: auto;
    }
  }

  .buttons {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    min-height: 50px;

    .button--primary {
      padding: 10px 20px;
    }
  }
}