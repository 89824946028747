@import 'assets/css/constants.scss';
.variant-step-engine {
    width: 80%;
    padding: 0;
  
    .header {
      padding: 20px 50px;
      height: 120px;
      max-width: 100%;
      background-color: #D7E1F3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .form-container {
      padding: 24px 24px 40px 24px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 120px);
      overflow: auto;
      gap: 25px;

      > div {
        display: flex;
        gap: 50px;
      }

      .add {
        min-width: 330px;
        height: 150px;

        button {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: #EAF1FF;
          border-radius: 12px;
          color: #256EFF;
          cursor: pointer;
          transition: all 0.3s ease;
          font-size: 16px;

          &:hover {
              background-color: #D7E1F3;
          }
        }
      }
  
      h3 {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 18px;
        min-width: 220px;
        color: #000000;
      }
  
      .forms {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 50px;
  
        h4 {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
        }
  
        > div {
          display: flex;
          flex-direction: column;
          gap: 18px;
        }
  
        .form {
          width: 330px;
          display: flex;
          flex-direction: column;
          gap: 18px;
          background-color: #F6F6F6;
          padding: 24px;
          border-radius: 24px;

          .title {
            display: flex;
            justify-content: space-between;

            > span {
                font-size: 16px;
                font-weight: 800;
                color: #000000;
            }

            button {
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
          }

          .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
  
          label {
            color: #525967;
            font-size: 12px;
          }
        }
      }
  
      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 24px;
  
        .button--primary {
          padding: 10px 20px;
        }
      }
    }
  }

