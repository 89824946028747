@import 'assets/css/constants.scss';

header {
  background-color: #2F2F2F;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  .separation {
    width: 200px;
    height: 70px;
    background-color: #2F2F2F;
    z-index: 999;
  }

  .animate-logo{
    width: 400px;
    height: auto;
    bottom: 20px;
    left: 0;

    svg {
      path {
        fill: white;
      }
    }

    .locomotive {
      position: absolute;
      width: 80px;
      height: auto;
      bottom: 20px;
      left: 0;

      &.image2 {
        animation: fadeLocomotive2 2s linear infinite, moveLocomotive 15s linear infinite;
      }
      &.image3 {
        animation: fadeLocomotive3 2s linear infinite, moveLocomotive 15s linear infinite;
      }
      &.image1 {
        opacity: 1;
        animation: moveLocomotive 15s linear infinite;
      }
    }
  }

  *, .MuiButtonBase-root {
    color: white;
  }

  .MuiButtonBase-root {
    font-weight: 800;
  }

  .logos {
    display: flex;
    gap: 28px;
  }

  // target div AccountMenu wrapper
  div:last-of-type {
    margin-left: auto;
  }

  nav {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #EEF3FB;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding: 0 16px;

      &:hover, &.active {
        background-color: #3A4457;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  header .logos > img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    .logos {
      display: none;
    }
  }
}

@keyframes moveLocomotive {
  0% {
    left: 700px;
  }
  100% {
    left: calc(100% - 400px);
  }
}

/* Animation pour image2 (un peu de fumée) */
@keyframes fadeLocomotive2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Animation pour image3 (beaucoup de fumée) */
@keyframes fadeLocomotive3 {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

